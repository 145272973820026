import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

/*
	just testing React stuff
*/

class App extends React.Component {
	state = {
		name : 'thijs',
		age : 32
	}
	handleClick = (e) => {
		console.log(e.target);
		console.log(this.state)
		this.setState({ age : 90 })
	}
	handleChange = (e) => {
		this.setState({
			name : e.target.value
		})
	}
	handleSubmit = (e) => {
		e.preventDefault();
		console.log('form submitted', this.state.name)
	}
	handleHover(e){
		console.log(e)
		console.log(e.target, e.pageX);
	}
	handleCopy(e){
		console.log('dont copy be original, ' + this.state.name )
	}
	render(){
		return ( 
			<div className="appComponent">
				<h1>Hello my name is { this.state.name }</h1>
				<p>my age is not {this.state.age*2}</p>
				<button onClick={ this.handleClick }>click me</button>
				<button onMouseOver={ this.handleHover }>Hover me</button>
				<p onCopy={ this.handleCopy}>what we think, we become</p>
				<form onSubmit={this.handleSubmit}>
					<input type="text" onChange={this.handleChange}/>
					<button>submit</button>
				</form>
			</div>
		)
	}
}

ReactDOM.render(<App />, document.getElementById("root"));

